var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "35%",
        "label-col": 4,
        "wrapper-col": 14,
        visible: _vm.openDataScope,
      },
      on: { close: _vm.onClose },
    },
    [
      _c("a-divider", { attrs: { orientation: "left" } }, [
        _c("b", [_vm._v(_vm._s(_vm.formTitle))]),
      ]),
      _c(
        "a-form-model",
        { ref: "form", attrs: { model: _vm.form } },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "用户名称", prop: "userName" } },
            [
              _c("a-input", {
                attrs: { disabled: true },
                model: {
                  value: _vm.form.userName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "userName", $$v)
                  },
                  expression: "form.userName",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "权限范围", prop: "dataScope" } },
            [
              _c(
                "a-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.form.dataScope,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "dataScope", $$v)
                    },
                    expression: "form.dataScope",
                  },
                },
                _vm._l(_vm.dataScopeOptions, function (d, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: d.value } },
                    [_vm._v(_vm._s(d.label))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.form.dataScope == 2,
                  expression: "form.dataScope == 2",
                },
              ],
              attrs: { label: "数据权限" },
            },
            [
              _c(
                "a-checkbox",
                {
                  on: {
                    change: function ($event) {
                      return _vm.handleCheckedTreeExpand($event)
                    },
                  },
                },
                [_vm._v(" 展开/折叠 ")]
              ),
              _c(
                "a-checkbox",
                {
                  on: {
                    change: function ($event) {
                      return _vm.handleCheckedTreeNodeAll($event)
                    },
                  },
                },
                [_vm._v(" 全选/全不选 ")]
              ),
              _c(
                "a-checkbox",
                {
                  attrs: { checked: _vm.form.deptCheckStrictly },
                  on: {
                    change: function ($event) {
                      return _vm.handleCheckedTreeConnect($event)
                    },
                  },
                },
                [_vm._v(" 父子联动 ")]
              ),
              _c("a-tree", {
                attrs: {
                  checkable: "",
                  checkStrictly: !_vm.form.deptCheckStrictly,
                  "expanded-keys": _vm.deptExpandedKeys,
                  "auto-expand-parent": _vm.autoExpandParent,
                  "tree-data": _vm.deptOptions,
                  replaceFields: _vm.defaultProps,
                },
                on: { expand: _vm.onExpandDept, check: _vm.onCheck },
                model: {
                  value: _vm.deptCheckedKeys,
                  callback: function ($$v) {
                    _vm.deptCheckedKeys = $$v
                  },
                  expression: "deptCheckedKeys",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "bottom-control" },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitDataScope },
                    },
                    [_vm._v(" 保存 ")]
                  ),
                  _c(
                    "a-button",
                    { attrs: { type: "dashed" }, on: { click: _vm.cancel } },
                    [_vm._v(" 取消 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }